

/*
*     This will move the buttons from side to hamburger menu.
* */
const navBar = document.getElementById('navbarNav')
const navbarWrapper = document.getElementById('navbarWrapper')
const ctaButtons = document.getElementById('ctaButtons')
const contactButtons = document.getElementById('contactUsButtons')

const ctaActiveClassName = 'ik-buttons-show-side'
const contactHiddenClassName = 'ik-contact-us-links-hidden'
const navbarMenuOpenClassName = 'ik-menu-open'

navBar.addEventListener('show.bs.collapse', function () { // bootstrap event -> show collapse
  ctaButtons.classList.toggle(ctaActiveClassName, false)
  contactButtons.classList.toggle(contactHiddenClassName, false)
  navbarWrapper.classList.toggle(navbarMenuOpenClassName, true)
})
navBar.addEventListener('hide.bs.collapse', function () { // bootstrap event -> hide collapse
  ctaButtons.classList.toggle(ctaActiveClassName, true)
  contactButtons.classList.toggle(contactHiddenClassName, true)
  navbarWrapper.classList.toggle(navbarMenuOpenClassName, false)
})

/*
*     This will navigate and update the take away menu
* */

const container = document.getElementById('ik-take-away-menu')
const menuItems = document.getElementsByClassName('ik-menu-item')
const menuItemsByHeight = {}

//Setup
Array.prototype.forEach.call(menuItems, (element, index) => {
  const targetId = element.getAttribute("href").substr(1)
  const targetElement = document.getElementById(targetId)
  if (targetElement == null) {
    return
  }

  const pos = parseInt(targetElement.getBoundingClientRect().top);
  menuItemsByHeight[pos] = element

  element.addEventListener('click', event => {
    event.preventDefault()
    SmoothVerticalScrolling(targetElement, 300);
  })
})



if (container != null) {
  container.addEventListener("touchmove", ScrollSpy, false);
  container.addEventListener("scroll", ScrollSpy, false);
}

/*
*  Custom ScrollSpy
* */
function ScrollSpy(e) {
  const scrollTop = container.scrollTop
  const scrollHeight = container.offsetHeight
  const scrollPosition = scrollTop + scrollHeight / 2; // center position on view!

  // remove all active flags first.
  for (const [key, value] of Object.entries(menuItemsByHeight)) {
    value.classList.remove("active")
  }

  // find last element that center point has not passed yet.
  let centerElement = null
  for (const [key, value] of Object.entries(menuItemsByHeight)) {
    const position = parseInt(key)
    if (position >= scrollPosition) {
      break;
    }
    centerElement = value
  }

  // if on last item then select last item.
  if (scrollTop + scrollHeight >= container.scrollHeight) {
    centerElement = menuItemsByHeight[Object.keys(menuItemsByHeight)[Object.keys(menuItemsByHeight).length - 1]];
  }

  if (centerElement != null && centerElement.classList != null) {
    centerElement.classList.add("active")
    SmoothHorizontalScrolling(centerElement, 100);
  }
}

/*
*  Smooth Scrolling.
* */
function SmoothVerticalScrolling(e, time) {
  const eTop = e.getBoundingClientRect().top - container.getBoundingClientRect().top
  const eAmt = eTop / 100
  let curTime = 0

  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt)
    curTime += time / 100
  }
}

function SVS_B(eAmt) {
  container.scrollBy({
    top: eAmt,
    left: 0,
  })
}


function SmoothHorizontalScrolling(el, time) {
  const leftPaddingStr = window.getComputedStyle(container).getPropertyValue('padding-left');
  const leftPadding = parseInt(leftPaddingStr.substr(0,leftPaddingStr.length-2));
  const eLeft = el.getBoundingClientRect().left - leftPadding

  containerTabs.scrollBy({
    top: 0,
    left: eLeft,
  })
}

const containerTabs = document.getElementById('list-example')
function SHS_B(eAmt) {
  containerTabs.scrollBy({
    top: 0,
    left: eAmt,
  })
}
